export const navigations = [
    {name: 'Dashboard', path: '/auth/dashboard', icon: 'dashboard', children: []},

    {
        name: 'CRM',
        icon: 'people',
        children: [
            {
                name: 'Utilizador',
                icon: 'folder',
                children: [
                    {
                        name: 'Utilizadores',
                        icon: 'groups',
                        path: '/auth/users/list',
                        children: []
                    },
                    {
                        name: 'Adicionar',
                        icon: 'add_circle',
                        path: '/auth/users/add',
                        children: []
                    },
                ],
            },
            {
                name: 'Entidade',
                icon: 'folder',
                children: [
                    {
                        name: 'Entidades',
                        icon: 'people',
                        path: '/auth/entity/list',
                        children: []
                    },
                    {
                        name: 'Contactos',
                        icon: 'contacts',
                        path: '/auth/entity/contacts',
                        children: []
                    },
                    {
                        name: 'Carregar',
                        icon: 'move_to_inbox',
                        path: '/auth/entity/charge/file',
                        children: []
                    },
                ],
            },
        ],
    },

    {
        name: 'Artigos',
        icon: 'all_inbox',
        children: [
            {
                name: 'PIM',
                icon: 'layers',
                children: [
                    {
                        name: 'Artigos',
                        icon: 'inventory',
                        path: '/auth/pim'
                    },
                    {
                        name: 'Carregar',
                        icon: 'move_to_inbox',
                        path: '/auth/pim/charge/file'
                    },
                ],
            },
            {
                name: 'Inventário',
                icon: 'local_offer',
                children: [
                    {
                        name: 'Localizações',
                        icon: 'location_on',
                        path: '/auth/inventory/location/list'
                    },
                    {
                        name: 'Stock',
                        icon: 'show_chart',
                        path: '/auth/inventory/stock'
                    },
                    {
                        name: 'Carregar',
                        icon: 'move_to_inbox',
                        path: '/auth/inventory/location/charge/file'
                    },
                ],
            },
        ]
    },

    {
        name: 'Gestor RH',
        icon: 'perm_contact_calendar',
        children: [
            {
                name: 'Picagens',
                icon: 'schedule',
                path: '/auth/rh/pick'
            },
            {
                name: 'Férias',
                icon: 'calendar_month',
                path: '/auth/rh/booking'
            },
            {
                name: 'Documentação',
                icon: 'folder',
                path: '/auth/rh/documents'
            },
            {
                name: 'Administração',
                icon: 'admin_panel_settings',
                path: '/auth/rh/administration'
            },
        ],
    },

    {
        name: 'Gestor de turnos',
        icon: 'track_changes',
        children: [
            {
                name: 'Turnos',
                icon: 'drag_indicator',
                path: '/auth/shift'
            },
        ],
    },


    {
        name: 'Tickets',
        icon: 'assignment',
        children: [
            {
                name: 'Geral',
                iconText: '➤',
                path: '/auth/Tickets/Main'
            },
            {
                name: 'Administração',
                iconText: '➤',
                path: '/auth/Tickets/Admin'
            },
        ],
    },
    {
        name: 'Despesas',
        icon: 'request_quote',
        children: [
            {
                name: 'Geral',
                iconText: '➤',
                path: '/auth/expense/main'
            },
            {
                name: 'Aprovações',
                iconText: '➤',
                path: '/auth/expense/management'
            },
            {
                name: 'Integração',
                iconText: '➤',
                path: '/auth/expense/financial'
            },
            {
                name: 'Classificação',
                iconText: '➤',
                path: '/auth/expense/classification'
            },
            {
                name: 'Fluxo',
                iconText: '➤',
                path: '/auth/expense/flow'
            },
            {
                name: 'Centro de custos',
                iconText: '➤',
                path: '/auth/expense/coastCenter'
            },
        ],
    },

    {
        name: 'IG',
        icon: 'menu_book',
        children: [
            {
                name: 'Orçamento',
                icon: 'request_page',
                path: '/auth/ig/management/budget'
            },
            {
                name: 'Produção',
                icon: 'precision_manufacturing',
                path: '/auth/ig/management/production'
            },
            {
                name: 'Requisições',
                icon: 'text_snippet',
                path: '/auth/ig/management/request'
            },
            {
                name: 'Calendário de produção',
                icon: 'calendar_month',
                path: '/auth/ig/management/schedular'
            },
            {
                name: 'Controlo de produção',
                icon: 'task',
                path: '/auth/ig/management/control'
            },

        ],
    },

    {
        name: 'TFP',
        icon: 'menu_book',
        children: [
            {name: 'Orçamento', iconText: '➤', path: '/auth/tfp/management/budget'},
            {name: 'Produção', iconText: '➤', path: '/auth/tfp/management/production'},
            {name: 'Calendário de produção', iconText: '➤', path: '/auth/tfp/management/schedular'},
        ],
    },

    {
        name: 'Manutenção',
        icon: 'handyman',
        children: [
            {name: 'Geral', iconText: '➤', path: '/auth/maintenance'},
            {name: 'Equipamentos', iconText: '➤', path: '/auth/maintenance/entities'},
            {name: 'Definições', iconText: '➤', path: '/auth/maintenance/settings'},
        ],
    },

    {
        name: 'Documentos',
        icon: 'description',
        children: [
            {name: 'Novo', iconText: '➤', path: '/auth/doc/new'},
            {name: 'Documentos', iconText: '➤', path: '/auth/doc/docs'},
        ],
    },
    {
        name: 'Eventos',
        icon: 'description',
        children: [
            {name: 'Listar', iconText: '➤', path: '/auth/events/list'}
        ],
    },
    {
        name: 'Iframes',
        icon: 'language',
        children: [
            {name: 'Main', icon: 'web_asset', path: '/auth/iframe'}
        ],
    },
    {
        name: 'Configurações',
        icon: 'settings',
        children: [
            {
                name: 'Empresa',
                icon: 'business',
                children: [
                    {name: 'Empresas', icon: 'store', path: '/auth/config/company/list'},
                    {name: 'Departamentos', icon: 'account_tree', path: '/auth/config/departments'},
                ]
            },

            {
                name: 'Acessos',
                icon: 'admin_panel_settings',
                children: [
                    {name: 'Permissões', icon: 'manage_accounts', path: '/auth/config/permitions/list'},
                    {name: 'Paginas', icon: 'web', path: '/auth/config/pages/list'},
                ]
            },

            {
                name: 'Plataforma',
                icon: 'all_inclusive',
                children: [
                    {name: 'Tabelas', icon: 'table_chart', path: '/auth/config/tables'},
                    {name: 'Campos adicionais', icon: 'playlist_add', path: '/auth/config/adicionalFields'},
                    {name: 'WebHooks', icon: 'webhook', path: '/auth/config/webHooks'},
                    {name: 'Report', icon: 'receipt_long', path: '/auth/config/report'},
                ]
            },


        ],
    },

];


/*

{
        name: 'Utilizadores',
        icon: 'people',
        children: [
            {
                name: 'Lista',
                iconText: '➤',
                path: '/auth/users/list',
                children: []
            },
            {
                name: 'Adicionar',
                iconText: '➤',
                path: '/auth/users/add',
                children: []
            },
        ],
    },
    {
        name: 'Entidades',
        icon: 'folder',
        children: [
            {
                name: 'Lista', iconText: '➤', path: '/auth/entity/list',
                children: []
            },
            {
                name: 'Adicionar',
                iconText: '➤',
                path: '/auth/entity/add',
                children: []
            },
            {name: 'Carregar entidades',
                iconText: '➤',
                path: '/auth/entity/charge/file',
                children: []},
        ],
    },
    {
        name: 'Gestor RH',
        icon: 'perm_contact_calendar',
        children: [
            {name: 'Picagens', iconText: '➤', path: '/auth/rh/pick'},
            {name: 'Férias', iconText: '➤', path: '/auth/rh/booking'},
            {name: 'Documentação', iconText: '➤', path: '/auth/rh/documents'},
            {name: 'Administração', iconText: '➤', path: '/auth/rh/administration'},
        ],
    },

    {
        name: 'Gestor de turnos',
        icon: 'track_changes',
        children: [
            {name: 'Turnos', iconText: '➤', path: '/auth/shift'},
        ],
    },

    {
        name: 'PIM',
        icon: 'layers',
        children: [
            {name: 'Artigos', iconText: '➤', path: '/auth/pim'},
            {name: 'Carregar artigos', iconText: '➤', path: '/auth/pim/charge/file'},
        ],
    },
    {
        name: 'Tickets',
        icon: 'assignment',
        children: [
            {name: 'Geral', iconText: '➤', path: '/auth/Tickets/Main'},
            {name: 'Administração', iconText: '➤', path: '/auth/Tickets/Admin'},
        ],
    },
    {
        name: 'Despesas',
        icon: 'request_quote',
        children: [
            {name: 'Geral', iconText: '➤', path: '/auth/expense/main'},
            {name: 'Aprovações', iconText: '➤', path: '/auth/expense/management'},
            {name: 'Integração', iconText: '➤', path: '/auth/expense/financial'},
            {name: 'Classificação', iconText: '➤', path: '/auth/expense/classification'},
            {name: 'Fluxo', iconText: '➤', path: '/auth/expense/flow'},
            {name: 'Centro de custos', iconText: '➤', path: '/auth/expense/coastCenter'},
        ],
    },
    {
        name: 'Inventário',
        icon: 'local_offer',
        children: [
            {name: 'Localizações', iconText: '➤', path: '/auth/inventory/location/list'},
            {name: 'Stock', iconText: '➤', path: '/auth/inventory/stock'},
            {name: 'Carregar localizações', iconText: '➤', path: '/auth/inventory/location/charge/file'},
        ],
    },
    {
        name: 'IG',
        icon: 'menu_book',
        children: [
            {name: 'Orçamento', iconText: '➤', path: '/auth/ig/management/budget'},
            {name: 'Produção', iconText: '➤', path: '/auth/ig/management/production'},
            {name: 'Requisições', iconText: '➤', path: '/auth/ig/management/request'},
            {name: 'Calendário de produção', iconText: '➤', path: '/auth/ig/management/schedular'},
            {name: 'Controlo de produção', iconText: '➤', path: '/auth/ig/management/control'},

        ],
    },

    {
        name: 'TFP',
        icon: 'menu_book',
        children: [
            {name: 'Orçamento', iconText: '➤', path: '/auth/tfp/management/budget'},
            {name: 'Produção', iconText: '➤', path: '/auth/tfp/management/production'},
            {name: 'Calendário de produção', iconText: '➤', path: '/auth/tfp/management/schedular'},
        ],
    },

    {
        name: 'Manutenção',
        icon: 'handyman',
        children: [
            {name: 'Geral', iconText: '➤', path: '/auth/maintenance'},
            {name: 'Equipamentos', iconText: '➤', path: '/auth/maintenance/entities'},
            {name: 'Definições', iconText: '➤', path: '/auth/maintenance/settings'},
        ],
    },

    {
        name: 'Documentos',
        icon: 'description',
        children: [
            {name: 'Novo', iconText: '➤', path: '/auth/doc/new'},
            {name: 'Documentos', iconText: '➤', path: '/auth/doc/docs'},
        ],
    },
    {
        name: 'Eventos',
        icon: 'description',
        children: [
            {name: 'Listar', iconText: '➤', path: '/auth/events/list'}
        ],
    },
    {
        name: 'Configurações',
        icon: 'settings',
        children: [
            {name: 'Empresas', iconText: '➤', path: '/auth/config/company/list'},
            {name: 'Departamentos', iconText: '➤', path: '/auth/config/departments'},
            {name: 'Permissões', iconText: '➤', path: '/auth/config/permitions/list'},
            {name: 'Paginas', iconText: '➤', path: '/auth/config/pages/list'},
            {name: 'Tabelas', iconText: '➤', path: '/auth/config/tables'},
            {name: 'Campos adicionais', iconText: '➤', path: '/auth/config/adicionalFields'},
            {name: 'WebHooks', iconText: '➤', path: '/auth/config/webHooks'},
            {name: 'Report', iconText: '➤', path: '/auth/config/report'},

        ],
    },

* */
