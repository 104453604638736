import React, {createContext, useEffect, useMemo, useReducer, useState} from 'react';
import axios from 'axios.js';
import useAuth from '../hooks/useAuth';
import {SaveLocalStorage, LoadLocalStorage} from '../utils/Utilities';
import useSignalR from '../hooks/useSignalR';
import useSettings from '../hooks/useSettings';
import {MatxLayoutSettings} from '../components/MatxLayout/settings';

const initialState = {
    companies: [],
    selectedCompany: null,
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'LOAD_COMPANYS':
            return {
                ...state,
                companies: action.payload,
            };
        case 'CLEAR_COMPANYS':
            return {
                ...state,
                companies: [],
            };
        case 'SELECTED_COMPANY':
            return {
                ...state,
                selectedCompany: action.payload,
            };
        default:
            return state;
    }
};

const CompanyContext = createContext({
    companies: null,
    selectedCompany: null,
    getCompanies: () => Promise.resolve(),
    getSelectedCompany: () => {
    },
    postSelectCompany: () => Promise.resolve(),
    clearCompanies: () => {
    },
});

export const CompanyProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const {user} = useAuth();
    const {updateSettings} = useSettings();
    const {connectionHub} = useSignalR();

    // Configure axios headers
    useEffect(() => {
        const loadSession = () => {
            const accessToken = localStorage.getItem('accessToken');
            if (accessToken) {
                axios.defaults.baseURL = process.env.REACT_APP_API_URL;
                axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
            } else {
                delete axios.defaults.headers.common.Authorization;
            }
        };
        loadSession();
    }, []);

    // Clear all companies
    const clearCompanies = async () => {
        if (!state.companies || state.companies.length === 0) {
            console.log('Nenhuma empresa para limpar.');
            return;
        }

        try {
            await axios.post('/Notification/delete-all');
            dispatch({type: 'CLEAR_COMPANYS'});
        } catch (e) {
            console.error('Erro ao limpar empresas:', e);
        }
    };

    // Load companies from the API
    const getCompanies = async () => {
        try {
            var loadCopanies = LoadLocalStorage("companies");
            if (loadCopanies !== null) {
                dispatch({type: 'LOAD_COMPANYS', payload: loadCopanies});
                return loadCopanies;
            } else {
                const res = await axios.get('/Company/GetCompanies');
                dispatch({type: 'LOAD_COMPANYS', payload: res.data});
                SaveLocalStorage("companies", res.data);
                return res.data;
            }
        } catch (e) {
            console.error('Erro ao buscar empresas:', e);
        }
    };

    // Get selected company from localStorage
    const getSelectedCompany = () => {
        try {
            return LoadLocalStorage('selectedCompany') || null;
        } catch (e) {
            console.error('Erro ao carregar empresa selecionada:', e);
            return null;
        }
    };

    // Save selected company in localStorage
    const setSelectedCompany = (selectedCompany) => {
        SaveLocalStorage('selectedCompany', selectedCompany || null);
    };

    // Handle company selection
    const postSelectCompany = async (company) => {
        try {
            const selectedCompanyId = LoadLocalStorage('selectedCompany')?.id;
            const companyId = company?.id;

            if (selectedCompanyId && selectedCompanyId !== companyId) {
                connectionHub?.send(
                    'SendMessageToGroup',
                    selectedCompanyId.toString(),
                    `${user?.firstName} desconectou-se da empresa.`
                );
            }

            dispatch({type: 'SELECTED_COMPANY', payload: company});
            setSelectedCompany(company);

            if (connectionHub && connectionHub.state === 'Connected' && companyId !== selectedCompanyId) {
                connectionHub?.send('ReJoinGroup', companyId?.toString());
                connectionHub?.send(
                    'SendMessageToGroup',
                    companyId?.toString(),
                    `${user?.firstName} conectou-se à empresa.`
                );
            }

            axios.defaults.headers.common['CompanyId'] = companyId ?? null;

            // Clear cache filters and update roles
            SaveLocalStorage('accountFilter', null);
            const responseRole = await GetUserPageRoles(company);
            SaveLocalStorage('accessRoles', responseRole);
        } catch (e) {
            console.error('Erro ao selecionar empresa:', e);
        }
    };

    // Fetch user page roles
    const GetUserPageRoles = async (company) => {
        try {
            const response = await axios.get('/ConfigPage/GetUserPageRoles', {
                params: {companyId: company?.id ?? null},
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao buscar papéis de página do usuário:', error);
            return [];
        }
    };

    // Fetch companies when user changes
    useEffect(() => {
        (async () => {
            if (user) {
                await getCompanies();
            }
        })();
    }, [user]);

    // Handle company auto-selection logic
    useEffect(() => {
        (async () => {
            try {
                if (user) {
                    const companies = state?.companies || [];
                    const decryptedSelectedCompany = getSelectedCompany();

                    if (companies.length) {
                        if (decryptedSelectedCompany && companies.some((c) => c.id === decryptedSelectedCompany.id)) {
                            await postSelectCompany(decryptedSelectedCompany);
                        } else if (!user?.accountRoles.some((role) => role.role === 'SA') && companies.length === 1) {
                            const company = companies[0];
                            await postSelectCompany(company);
                            updateSettings(
                                company?.themeSettings
                                    ? JSON.parse(company.themeSettings)
                                    : MatxLayoutSettings
                            );
                        } else {
                            await postSelectCompany(null);
                        }
                    } else {
                        await postSelectCompany(decryptedSelectedCompany);
                    }
                }
            } catch (err) {
                console.error('Erro ao gerenciar seleção de empresa:', err);
                await postSelectCompany(null);
            }
        })();
    }, [state?.companies, user]);

    return (
        <CompanyContext.Provider
            value={{
                companies: state?.companies || [],
                selectedCompany: state?.selectedCompany || null,
                clearCompanies,
                getCompanies,
                postSelectCompany,
                getSelectedCompany,
            }}
        >
            {children}
        </CompanyContext.Provider>
    );
};

export default CompanyContext;
