import useAuth from 'app/hooks/useAuth';
//import { Navigate, useLocation } from 'react-router-dom';
import { Navigate, useLocation } from 'react-router';
import { flat } from 'app/utils/utils';
import AllPages from '../routes';
import CryptoJS from 'crypto-js';
import { BasePathAcess } from 'app/utils/Utilities';

const userHasPermission = (pathname, user, routes) => {
    if (!user) {
        return false;
    }

    if (user.accountRoles.some(item => item.role === "SA")) {
        return true;
    }
    if (BasePathAcess().includes(pathname.toLowerCase())) {
        return true;
    }

    const matched = routes.find((r) => r.path === pathname);
    let hasPermission = false;
    var accessRoles = getAccessRoles();
    const authRoles = accessRoles.find(value => value?.path === pathname)?.roles || [];
    if (authRoles.length > 0) {
        const matchedRoles = authRoles ?? matched.auth;
        hasPermission = matchedRoles?.some(role => {
            if (role?.roleId === -1) { return true; }
            return user?.accountRoles.some(accountRole => role?.role === accountRole.role);
        });
    }

    return hasPermission;
};


const getAccessRoles = () => {
    var encryptedText = localStorage.getItem('accessRoles');
    try {
        const bytes = CryptoJS.AES.decrypt(encryptedText, process.env.REACT_APP_AES_KEY);
        const accessRoles = bytes.toString(CryptoJS.enc.Utf8);
        if (accessRoles) {
            return JSON.parse(accessRoles);
        } else {
            return [];
        }
    } catch (ex) {
        console.error("Roles adulterados.");
        return [];
    }
}


const AuthGuard = ({ children }) => {

    let { isAuthenticated, user } = useAuth();
    const { pathname } = useLocation();
    const routes = flat(AllPages);

    const hasPermission = userHasPermission(pathname, user, routes);
    let authenticated = isAuthenticated && hasPermission;

    return authenticated ?
        children :
        <Navigate replace to="/signin" state={{ from: pathname }} />;
};

export default AuthGuard;
